import React from 'react'
import styled from '@emotion/styled'

interface QuestionSetProps {
  children?: React.ReactNode
}

const QuestionSetLayout = styled.div({
  width: '100%',
})

const QuestionSet: React.FC<QuestionSetProps> = ({ children }: QuestionSetProps) => (
  <QuestionSetLayout>{children}</QuestionSetLayout>
)

export default QuestionSet
