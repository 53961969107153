import styled from '@emotion/styled/macro'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

import CourseQuestion from 'components/CourseQuestion'

interface CourseProps {
  videoId?: string
}

interface PlayerStylesProps {
  loading?: number
}

interface Video {
  id: string
  title: string
  youtubeUrl: string
}

interface VideoResponse {
  id: string
  title: string
  youtube_url: string
}

const loadingStyle = {
  position: 'absolute',
  display: 'block',
  content: '""',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  transform: 'translateX(-100%)',
  animation: '1.5s loading linear 0.2s infinite',
  backgroundImage: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, .08), transparent)',
  zIndex: 1,
}

const CourseLayout = styled.div({
  width: 940,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  '@media (max-width: 1600px)': {
    width: 720,
  },
  '@media (max-width: 1200px)': {
    width: 480,
  },
  '@media (max-width: 764px)': {
    width: 320,
  },
})

const Player = styled.div<PlayerStylesProps>(({ loading }) => ({
  position: 'relative',
  width: '100%',
  height: 480,
  backgroundColor: '#e2e2e2',
  overflow: 'hidden',
  '::after': loading ? loadingStyle : {},
  '@media (max-width: 1600px)': {
    height: 400,
  },
  '@media (max-width: 1200px)': {
    height: 320,
  },
  '@media (max-width: 764px)': {
    height: 240,
  },
}))

interface PlayerHeaderStylesProps {
  loading?: number
}

const PlayerMainTitle = styled.span<PlayerMainTitleStylesProps>({
  position: 'relative',
  display: 'inline-block',
  width: '100%',
  fontSize: 16,
  fontWeight: 600,
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const PlayerHeader = styled.h1<PlayerHeaderStylesProps>(({ loading }) => ({
  width: '100%',
  backgroundColor: '#ffffff',
  borderRadius: '0 0 10px 10px',
  padding: '20px 0 20px 20px',
  boxSizing: 'border-box',
  lineHeight: 1.4,
  [`${PlayerMainTitle}`]: {
    maxWidth: loading ? '20em' : 'auto',
    height: loading ? '1.2em' : 'auto',
    borderRadius: loading ? 6 : 0,
    backgroundColor: loading ? '#f2f2f2' : 'transparent',
    '::after': loading ? loadingStyle : {},
  },
}))

interface PlayerMainTitleStylesProps {
  loading?: number
}

const YoutubePlayer = styled.iframe({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: 'none',
})

const Course: React.FC<CourseProps> = ({ videoId }: CourseProps) => {
  const [video, setVideo] = useState<Video | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!videoId) return
    setLoading(true)

    const loadAsyncStuff = async () => {
      try {
        const response = await axios.get<VideoResponse>(
          `https://classhub-backend.dev.azure.riiid.cloud/videos/${videoId}`,
        )
        const data = response.data
        const video = { id: data.id, title: data.title, youtubeUrl: data.youtube_url }
        setVideo(video)
      } catch (error) {
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }

    loadAsyncStuff()
  }, [videoId])

  return (
    <CourseLayout>
      <Player loading={loading ? 1 : 0}>
        {!loading && (
          <YoutubePlayer
            src={video?.youtubeUrl}
            title='YouTube video player'
            allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        )}
      </Player>
      <PlayerHeader loading={loading ? 1 : 0}>
        <PlayerMainTitle>{!loading && video?.title}</PlayerMainTitle>
      </PlayerHeader>
      <CourseQuestion videoId={videoId} />
    </CourseLayout>
  )
}

export default Course
