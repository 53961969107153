import styled from '@emotion/styled'
import LogoSvg from 'assets/logo.svg'
import React from 'react'
import { Link } from 'react-router-dom'

const LogoLink = styled(Link)({})
const LogoIcon = styled.img({
  width: 30,
})

const Logo: React.FC = () => (
  <LogoLink to='/'>
    <LogoIcon src={LogoSvg} />
  </LogoLink>
)

export default Logo
