import styled from '@emotion/styled/macro'
import React from 'react'
import { Link } from 'react-router-dom'
import ProgressiveImage from 'react-progressive-graceful-image'

interface CardProps {
  id: string
  title: string
  thumbnailUrl: string
  loading?: number
  tags: string[]
}

interface CardLayoutStylesProps {
  loading?: number
}

interface ThumbnailStylesProps {
  loading?: number
}

interface CardTitleStylesProps {
  loading?: number
}

interface CardTagItemStylesProps {
  loading?: number
}

const loadingStyle = {
  position: 'absolute',
  display: 'block',
  content: '""',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  transform: 'translateX(-100%)',
  animation: '1.5s loading linear 0.2s infinite',
  backgroundImage: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, .08), transparent)',
  zIndex: 1
}

const CardLayout = styled(Link)<CardLayoutStylesProps>(({ loading }: CardLayoutStylesProps) => ({
  display: 'inline-block',
  backgroundColor: loading ? 'transparent' : '#fff',
  borderRadius: 10,
  gridColumn: 'span 2 / auto',
  transition: 'box-shadow .2s ease, transform .2s ease',
  textDecoration: 'none',
  overflow: 'hidden',
  ':hover': {
    boxShadow: '0 3px 8px rgba(0, 0, 0, .125)',
    transform: 'translate3d(0, -6px, 0)',
  },
}))

const CardLayoutLoading = styled(CardLayout.withComponent('div'))({
  ':hover': {
    boxShadow: 'none',
    transform: 'none',
  }
})

const Thumbnail = styled.div<ThumbnailStylesProps>(({ loading }: ThumbnailStylesProps) => ({
  position: 'relative',
  height: 160,
  borderRadius: loading ? 10 : 0,
  backgroundColor: '#e2e2e2',
  transition: 'height .2s ease',
  '& > img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'opacity .3s ease',
  },
  '::after': (loading ? loadingStyle : {}),
  '@media (max-width: 1600px)': {
    height: 134,
  },
  '@media (max-width: 1200px)': {
    height: 120,
  },
  '@media (max-width: 764px)': {
    height: 112,
  },
}))

const CardTitle = styled.h3<CardTitleStylesProps>(({ loading }: CardTitleStylesProps) => ({
  position: 'relative',
  height: '2.5em',
  fontSize: '1em',
  fontWeight: 400,
  backgroundColor: loading ? '#e2e2e2' : 'transparent',
  borderRadius: loading ? 5 : 0,
  overflow: 'hidden',
  '::after': (loading ? loadingStyle : {}),
}))

const CardTitleInner = styled.p({
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  fontSize: '.875em',
  lineHeight: 1.2,
  overflow: 'hidden',
})

const CardTag = styled.p({
  marginTop: 6,
})

const CardTagItem = styled.span<CardTagItemStylesProps>(({ loading }: CardTagItemStylesProps) => ({
  position: 'relative',
  display: 'inline-block',
  minWidth: loading ? 80 : 30,
  maxWidth: 200,
  height: '1.15em',
  borderRadius: loading ? 5 : 0,
  marginRight: '.375em',
  fontSize: '.75em',
  lineHeight: '1.15em',
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
  backgroundColor: loading ? '#e2e2e2' : 'transparent',
  color: '#713fff',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  ':last-of-type': {
    marginRight: 0,
  },
  '::after': (loading ? loadingStyle : {}),
}))

const CardContent = styled.div({
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  padding: '1.25em',
  boxSizing: 'border-box',
  color: '#333',
  lineHeight: 1.2,
})

const Card: React.FC<CardProps> = ({ id, title, thumbnailUrl, loading, tags }: CardProps) => {
  const CardConditionalLayout = loading ? CardLayoutLoading : CardLayout
  const dummyTags = Array.from(Array(2).keys()).map(() => '')
  return (
    <CardConditionalLayout to={`/viewer/${id}`} loading={loading}>
      <Thumbnail loading={loading}>
        {thumbnailUrl &&<ProgressiveImage src={thumbnailUrl} placeholder={thumbnailUrl}>
          {(src, imageLoading) => <img src={src} style={{ opacity: imageLoading ? 0 : 1 }} />}
        </ProgressiveImage>}
      </Thumbnail>
      <CardContent>
        <CardTitle loading={loading}>
          <CardTitleInner>{title}</CardTitleInner>
        </CardTitle>
        <CardTag>
          {(loading ? dummyTags : tags).map((tag, i) => <CardTagItem key={String(i)} loading={loading}>{tag}</CardTagItem>)}
        </CardTag>
      </CardContent>
    </CardConditionalLayout>
  )
  }

export default Card
