import styled from '@emotion/styled'
import React, { useEffect, useRef } from 'react'
import { RiSearch2Line, RiCloseLine } from 'react-icons/ri'
import { useNavigate, createSearchParams } from 'react-router-dom'

interface SearchOverlayProps {
  visible?: boolean
  onClose?: () => void
}

interface SearchOverlayLayoutStyleProps {
  visible?: boolean
}

const SearchOverlayLayout = styled.div<SearchOverlayLayoutStyleProps>(
  ({ visible }: SearchOverlayLayoutStyleProps) => ({
    display: visible ? 'block' : 'none',
    position: 'fixed',
    flex: '0 0 100%',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    backgroundColor: 'rgba(255, 255, 255, .75)',
    backdropFilter: 'blur(10px)',
  }),
)

const Inner = styled.div({
  position: 'relative',
  maxWidth: 1200,
  width: '100%',
  height: '100%',
  margin: '0 auto',
  textAlign: 'center',
  padding: '100px 0',
  overflow: 'hidden',
})

const SearchBarWrapper = styled.div({
  position: 'relative',
  width: '100%',
})

const SearchBar = styled.input({
  width: '100%',
  fontSize: 32,
  padding: '12px 64px 12px 16px',
  backgroundColor: 'transparent',
  border: 'none',
  borderBottom: '2px solid #ccc',
  color: '#555',
  boxSizing: 'border-box',
  outline: 'none',
})

const SearchIcon = styled(RiSearch2Line)({
  position: 'absolute',
  fontSize: 32,
  color: '#555',
  top: '50%',
  right: 20,
  marginTop: -16,
  zIndex: 1,
})

const CloseButton = styled.button({
  display: 'flex',
  width: 48,
  height: 48,
  justifyContent: 'center',
  alignItems: 'center',
  margin: '80px auto 0',
  border: '2px solid #999',
  borderRadius: 24,
  color: '#999',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  boxSizing: 'border-box',
  ':hover': {
    color: '#ccc',
    borderColor: '#ccc',
  },
  ':active': {
    color: '#555',
    borderColor: '#555',
  },
})

const CloseIcon = styled(RiCloseLine)({
  fontSize: 32,
})

const SearchOverlay: React.FC<SearchOverlayProps> = ({ visible, onClose }: SearchOverlayProps) => {
  const searchBarRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose?.()
    }
  }

  const handleOnKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      const keyword = searchBarRef.current && searchBarRef.current.value
      navigate({
        pathname: '/',
        search: createSearchParams({
          search_keyword: String(keyword),
        }).toString()
      })
      onClose?.()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    if (searchBarRef.current) searchBarRef.current.value = ''
  }, [visible])

  useEffect(() => {
    if (visible && searchBarRef.current) {
      searchBarRef.current.focus()
    }
  }, [visible])

  return (
    <SearchOverlayLayout visible={visible}>
      <Inner>
        <SearchBarWrapper>
          <SearchBar ref={searchBarRef} onKeyPress={handleOnKeyPress} />
          <SearchIcon />
        </SearchBarWrapper>
        <CloseButton onClick={() => onClose?.()}>
          <CloseIcon />
        </CloseButton>
      </Inner>
    </SearchOverlayLayout>
  )
}

export default SearchOverlay
