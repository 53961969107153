import { css, Global } from '@emotion/react'
import React from 'react'

const styles = `
@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  src: local('Lexend Deca'), url('/fonts/LexendDecaRegular.woff') format('woff');
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
`

const GlobalStyles: React.FC = () => (
  <>
    <Global
      styles={{
        'html, body, #root': {
          height: '100%',
          fontSize: 16,
        },
        'html, body, #root, *': {
          fontFamily: '"Lexend Deca", sans-serif',
          outline: 'none',
          '::selection': {
            backgroundColor: 'rgba(113, 63, 255, .9)',
            color: '#fff',
          },
        },
        '@media (max-width: 1440px)': {
          'html, body, #root': {
            fontSize: 14,
          },
        },
        '@media (max-width: 1024px)': {
          'html, body, #root': {
            fontSize: 13,
          },
        },
        '@media (max-width: 764px)': {
          'html, body, #root': {
            fontSize: 12,
          },
        },
      }}
    />
    <Global
      styles={css`
        ${styles}
      `}
    />
  </>
)

export default GlobalStyles
