import { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import ShareLink from 'components/ShareLink'

interface Menu {
  id: string
  title: string
}

interface CourseNavProps {
  videoId?: string
  menus?: Menu[]
  onClick?: (videoId: string) => void
}

interface CourseNavMenuStylesProps {
  active?: boolean
  loading?: number
}

const loadingStyle = {
  position: 'absolute',
  display: 'block',
  content: '""',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  transform: 'translateX(-100%)',
  animation: '1.5s loading linear 0.2s infinite',
  backgroundImage: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, .08), transparent)',
  zIndex: 1,
}

const CourseNavLayout = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginLeft: 40,
  overflow: 'hidden',
})

const CourseNavInner = styled.nav({
  width: '100%',
  maxHeight: '60vh',
  overflow: 'hidden',
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    width: 6,
    backgroundColor: 'transparent',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, .2)',
    borderRadius: 3,
    transition: 'background-color .2s ease',
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, .35)',
    },
    ':active': {
      backgroundColor: 'rgba(0, 0, 0, .5)',
    },
  },
})

const CourseNavMenu = styled.button<CourseNavMenuStylesProps>(
  ({ active, loading }: CourseNavMenuStylesProps) => ({
    position: 'relative',
    display: 'block',
    width: loading ? 240 : '100%',
    height: '1.5em',
    marginBottom: 10,
    backgroundColor: loading ? '#e2e2e2' : 'transparent',
    border: 'none',
    borderRadius: loading ? 5 : 0,
    color: active ? '#713fff' : '#555',
    cursor: 'pointer',
    fontSize: '.75em',
    fontWeight: 600,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
    lineHeight: 1.2,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    transition: 'transform .3s ease, color .2s ease',
    ':hover': {
      transform: 'translate3d(-6px, 0, 0)',
      color: '#713fff',
    },
    '::after': loading ? loadingStyle : {},
  }),
)

const CourseNav: React.FC<CourseNavProps> = ({ videoId, menus, onClick }: CourseNavProps) => {
  const [isScrolled, setIsScrolled] = useState(false)
  const dummyMenus = Array.from(Array(5).keys()).map<Menu>((key) => ({
    id: String(key),
    title: '',
  }))
  const activeNavItemRef = useRef<HTMLButtonElement>(null)
  const handleNavMenuClick = (newVideoId: string) => {
    onClick?.(newVideoId)
  }
  const loading = menus?.length ? 0 : 1

  useEffect(() => {
    if (activeNavItemRef?.current && !loading && !isScrolled) {
      activeNavItemRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
      setIsScrolled(true)
    }
  }, [activeNavItemRef.current, loading, isScrolled])

  return (
    <CourseNavLayout>
      <CourseNavInner>
        {(loading || !menus ? dummyMenus : menus).map(({ id, title }, i) => (
          <CourseNavMenu
            key={id}
            loading={loading}
            active={videoId === id}
            ref={videoId === id ? activeNavItemRef : undefined}
            onClick={() => handleNavMenuClick(id)}
          >
            {loading ? '' : `${i + 1}. ${title}`}
          </CourseNavMenu>
        ))}
      </CourseNavInner>
      <ShareLink />
    </CourseNavLayout>
  )
}

export default CourseNav
