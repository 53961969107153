import styled from '@emotion/styled'
import GlobalStyles from 'GlobalStyles'
import Main from 'pages/Main'
import Viewer from 'pages/Viewer'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Reset } from 'styled-reset'

const AppLayout = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

const App: React.FC = () => (
  <AppLayout>
    <Reset />
    <GlobalStyles />
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/:category' element={<Main />} />
        <Route path='/viewer/:courseId' element={<Viewer />} />
        <Route path='/viewer/:courseId/video/:videoId' element={<Viewer />} />
      </Routes>
    </BrowserRouter>
  </AppLayout>
)

export default App
