import styled from '@emotion/styled'
import React from 'react'

interface BodyProps {
  children: React.ReactNode
}

const BodyLayout = styled.div({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#f2f2f2',
  flex: 'auto',
})
const Inner = styled.div({
  display: 'flex',
  maxWidth: 2000,
  width: '100%',
  padding: '40px 20px',
  justifyContent: 'space-between',
  fontWeight: 400,
  boxSizing: 'border-box',
  overflowY: 'auto',
})
const Body: React.FC<BodyProps> = ({ children }) => (
  <BodyLayout>
    <Inner>{children}</Inner>
  </BodyLayout>
)
export default Body
