import Content from 'components/Content'
import Nav from 'components/Nav'
import Body from 'layouts/Body'
import Header from 'layouts/Header'
import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

const Main: React.FC = () => {
  const { category = localStorage.getItem('category') || 'development' } = useParams()
  const [searchParams, _] = useSearchParams()
  const searchKeyword = searchParams.get('search_keyword') || ''
  const allCategory = 'all'

  return (
    <>
      <Header />
      <Body>
        <Nav category={category} />
        <Content category={searchKeyword ? allCategory : category} searchKeyword={searchKeyword} />
      </Body>
    </>
  )
}
export default Main
