import styled from '@emotion/styled'
import React, { useState } from 'react'
import { shuffleFisherYates } from 'utils/array'

export interface QuestionData {
  title: string
  answer: string
  distractors: string[]
  explanation?: string
}

enum OptionState {
  Normal = 0,
  Correct = 1,
  Incorrect = 2,
  Disable = 3,
}

interface QuestionProps {
  index: number
  question: QuestionData
}

interface QuestionChoiceStyleProps {
  state: OptionState
  isEnable: boolean
}

const QuestionLayout = styled.div({
  marginTop: 40,
  marginBottom: 40,
  padding: 40,
  borderRadius: 10,
  backgroundColor: '#fafafa',
})

const QuestionTitle = styled.h3({
  fontSize: 20,
  fontWeight: 600,
  marginBottom: 20,
})

const QuestionExplanation = styled.div({
  padding: 20,
  backgroundColor: '#f1f1f1',
  borderRadius: 10,
  margin: '30px 0 20px 0',
  color: '#555',
  lineHeight: 1.6,
})

const QuestionChoices = styled.ul({
  listStyle: 'none',
})

const QuestionNumber = styled.span({
  display: 'inline-block',
  width: 26,
  height: 26,
  textAlign: 'center',
  lineHeight: '26px',
  borderRadius: 13,
  border: '1px solid #ccc',
  marginRight: 10,
})

const QuestionChoice = styled.li<QuestionChoiceStyleProps>(
  ({ isEnable, state }: QuestionChoiceStyleProps) => {
    const colors = {
      [OptionState.Normal]: '#555',
      [OptionState.Correct]: '#00bb99',
      [OptionState.Incorrect]: '#ff3838',
      [OptionState.Disable]: '#ccc',
    }
    return {
      padding: 10,
      borderRadius: 8,
      cursor: isEnable ? 'pointer' : 'not-allowed',
      transition: 'background-color .3s ease',
      color: colors[state],
      textDecoration: state === OptionState.Incorrect ? '#ff3838 wavy underline' : 'none',
      lineHeight: 1.6,
      ...(isEnable
        ? {
            ':hover': {
              backgroundColor: '#e2e2e2',
            },
          }
        : undefined),
    }
  },
)

type OptionInfo = { state: OptionState; value: string }

const Question: React.FC<QuestionProps> = ({ index, question }: QuestionProps) => {
  const candidateOptions = shuffleFisherYates([question.answer, ...question.distractors])
  const optionsMap: { [key: string]: OptionInfo } = candidateOptions.reduce(
    (acc, cur) => ({
      [cur]: {
        state: OptionState.Normal,
        value: cur,
      },
      ...acc,
    }),
    {},
  )
  const [options, setOptions] = useState(optionsMap)
  const [isEnable, setIsEnable] = useState(true)
  const onClickOptionHandler = (option: string) => {
    if (!isEnable) return

    setIsEnable(false)
    const newOptions = Object.entries(options).reduce((acc, [key, { value }]) => {
      const isCurrentOption = key === option
      const isAnswer = key === question.answer
      const newState =
        isCurrentOption || isAnswer
          ? key === question.answer
            ? OptionState.Correct
            : OptionState.Incorrect
          : OptionState.Disable

      return {
        ...acc,
        [key]: {
          value,
          state: newState,
        },
      }
    }, {})
    setOptions(newOptions)
  }

  console.log(question)

  return (
    <QuestionLayout>
      <QuestionTitle>
        Q{index}. {question.title}
      </QuestionTitle>
      {!isEnable && question.explanation ? (
        <QuestionExplanation>{question.explanation}</QuestionExplanation>
      ) : null}
      <QuestionChoices>
        {Object.entries(options).map(([key, { state, value }], i) => (
          <QuestionChoice
            isEnable={isEnable}
            state={state}
            key={key}
            onClick={() => onClickOptionHandler(key)}
          >
            <QuestionNumber>{String.fromCharCode(65 + i)}</QuestionNumber>
            {value}
          </QuestionChoice>
        ))}
      </QuestionChoices>
    </QuestionLayout>
  )
}

export default Question
