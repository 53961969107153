import styled from '@emotion/styled'
import Logo from 'components/Logo'
import SearchBar from 'components/SearchBar'
import SearchOverlay from 'components/SearchOverlay'
import React, { useState } from 'react'

const HeaderBar = styled.header({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px 0',
  borderBottom: '1px solid #eaeaea',
  boxSizing: 'border-box',
})

const Inner = styled.section({
  display: 'flex',
  alignItems: 'center',
  maxWidth: 2000,
  width: '100%',
  height: 39,
  padding: '0 20px',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
})

const Header: React.FC = () => {
  const [isSearchMode, setIsSearchMode] = useState(false)
  const onSearchOpenHandler = () => {
    setIsSearchMode(true)
  }
  const onCloseHandler = () => {
    setIsSearchMode(false)
  }
  return (
    <>
      <HeaderBar>
        <Inner>
          <Logo />
          <SearchBar onSearchOpen={onSearchOpenHandler} />
        </Inner>
      </HeaderBar>
      <SearchOverlay visible={isSearchMode} onClose={onCloseHandler}></SearchOverlay>
    </>
  )
}

export default Header
