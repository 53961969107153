import styled from '@emotion/styled'
import { RiSearch2Line } from 'react-icons/ri'

interface SearchBarProps {
  onSearchOpen?: () => void
}

const SearchBarLayout = styled.div({
  color: '#999',
})

const SearchButton = styled.button({
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  color: '#333',
  ':hover': {
    color: '#888',
  },
  ':active': {
    color: '#111',
  },
})

const SearchIcon = styled(RiSearch2Line)({
  fontSize: '2em',
})

const SearchBar: React.FC<SearchBarProps> = ({ onSearchOpen }: SearchBarProps) => (
  <SearchBarLayout>
    <SearchButton onClick={() => onSearchOpen?.()}>
      <SearchIcon />
    </SearchButton>
  </SearchBarLayout>
)

export default SearchBar
