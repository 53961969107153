import styled from '@emotion/styled'
import axios from 'axios'
import Course from 'components/Course'
import CourseNav from 'components/CourseNav'
import Body from 'layouts/Body'
import Header from 'layouts/Header'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface VideoResponse {
  id: string
  title: string
  youtube_url: string
}

interface Menu {
  id: string
  title: string
}

const ViewerLayout = styled.div({
  maxWidth: 1440,
  width: '100%',
  margin: '0 auto',
})

const ViewerContentLayout = styled.main({
  display: 'flex',
  width: '100%',
  alignItems: 'stretch',
  flexDirection: 'row',
})

const DebugBox = styled.div({
  width: '100%',
  backgroundColor: '#e2e2e2',
  padding: 20,
  marginTop: 40,
})

const Viewer: React.FC = () => {
  const { courseId: paramCourseId, videoId: paramVideoId } = useParams()
  const [videoId, setVideoId] = useState(paramVideoId)
  const [menus, setMenus] = useState<Menu[]>([])
  const navigate = useNavigate()

  const handleCourseNavClick = (videoId: string) => {
    navigate(`/viewer/${paramCourseId}/video/${videoId}`)
  }

  useEffect(() => {
    setVideoId(paramVideoId)
  }, [paramVideoId])

  useEffect(() => {
    const loadAsyncStuff = async () => {
      try {
        const response = await axios.get<VideoResponse[]>(
          `https://classhub-backend.dev.azure.riiid.cloud/courses/${paramCourseId}/videos`,
        )
        const data = response.data.map(({ id, title, youtube_url: youtubeUrl }) => ({
          id,
          title,
          youtubeUrl,
        }))
        setMenus(data.map(({ id, title }) => ({ id, title })))
        if (data && !videoId) setVideoId(data[0].id)
      } catch (error) {
        console.error(error)
      }
    }

    loadAsyncStuff()
  }, [paramCourseId])

  return (
    <>
      <Header />
      <Body>
        <ViewerLayout>
          <ViewerContentLayout>
            <Course videoId={videoId} />
            <CourseNav videoId={videoId} menus={menus} onClick={handleCourseNavClick} />
          </ViewerContentLayout>
          <DebugBox>
            for debug, course_id={paramCourseId}, video_id={videoId}
          </DebugBox>
        </ViewerLayout>
      </Body>
    </>
  )
}
export default Viewer
