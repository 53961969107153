import styled from '@emotion/styled'
import axios from 'axios'
import Question, { QuestionData } from 'components/Question'
import QuestionSet from 'components/QuestionSet'
import React, { useCallback, useEffect, useState } from 'react'
import { RiArrowDownLine } from 'react-icons/ri'

interface CourseQuestionProps {
  videoId?: string
}

interface QuestionButtonStylesProps {
  loading?: number
}

const loadingStyle = {
  position: 'absolute',
  display: 'block',
  content: '""',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  transform: 'translateX(-100%)',
  animation: '1.5s loading linear 0.2s infinite',
  backgroundImage: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, .08), transparent)',
  zIndex: 1,
}

const QuizButton = styled.button<QuestionButtonStylesProps>(({ loading }) => ({
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: 80,
  fontSize: '1em',
  fontWeight: 600,
  backgroundColor: '#e2e2e2',
  marginTop: 40,
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  borderRadius: 10,
  boxSizing: 'border-box',
  cursor: 'pointer',
  transition: 'background-color .2s ease',
  overflow: 'hidden',
  '::after': loading ? loadingStyle : {},
  ':hover': {
    backgroundColor: '#eaeaea',
  },
  ':active': {
    backgroundColor: '#dadada',
  },
}))

interface QuizButtonTextStylesProps {
  loading?: number
}

const QuizButtonText = styled.span<QuizButtonTextStylesProps>(({ loading }) => ({
  position: 'relative',
  display: 'inline-block',
  minWidth: loading ? '16em' : 'none',
  height: loading ? '1.6em' : 'auto',
  marginLeft: loading ? 0 : 6,
  borderRadius: loading ? 6 : 0,
  backgroundColor: loading ? 'rgba(0, 0, 0, .05)' : 'transparent',
  overflow: 'hidden',
  '::after': loading ? loadingStyle : {},
}))

const CourseQuestionLayout = styled.div({
  width: '100%',
})

const CourseQuestion: React.FC<CourseQuestionProps> = ({ videoId }: CourseQuestionProps) => {
  const [loading, setLoading] = useState(true)
  const [questionMode, setQuestionMode] = useState(false)
  const [questions, setQuestions] = useState<QuestionData[]>([])

  useEffect(() => {
    setQuestionMode(false)
    setQuestions([])

    if (!videoId) return

    const fetchQuestions = async () => {
      const response = await axios.get<QuestionData[]>(
        `https://classhub-backend.dev.azure.riiid.cloud/questions/${videoId}`,
      )
      const data = response.data
      setLoading(false)
      setQuestions(data)
    }
    fetchQuestions()
  }, [videoId])

  const handleStartQuestion = useCallback(() => {
    if (loading) return
    setQuestionMode(true)
  }, [loading])

  const QuestionButton =
    loading || questions.length ? (
      <QuizButton loading={loading ? 1 : 0} onClick={handleStartQuestion}>
        {loading ? null : <RiArrowDownLine />}
        <QuizButtonText loading={loading ? 1 : 0}>{loading ? '' : 'Start Quiz'}</QuizButtonText>
      </QuizButton>
    ) : null

  return videoId ? (
    <CourseQuestionLayout>
      {questionMode ? (
        <QuestionSet>
          {questions.map((question, i) => (
            <Question key={i} index={i + 1} question={question} />
          ))}
        </QuestionSet>
      ) : (
        QuestionButton
      )}
    </CourseQuestionLayout>
  ) : null
}

export default CourseQuestion
