import styled from '@emotion/styled'
import React from 'react'

import DataAnalysisImage from 'assets/emojis/data_analysis.png'
import DesignImage from 'assets/emojis/design.png'
import DevelopmentImage from 'assets/emojis/development.png'
import EconomicsImage from 'assets/emojis/economics.png'
import HistoryImage from 'assets/emojis/history.png'
import MachineLearningImage from 'assets/emojis/machine_learning.png'
import MathmaticsImage from 'assets/emojis/mathmatics.png'
import ProjectManagementImage from 'assets/emojis/project_management.png'
import ScienceImage from 'assets/emojis/science.png'
import { useNavigate } from 'react-router-dom'

interface NavProps {
  category?: string
}

interface MenuStylesProps {
  active?: boolean
}

const NavLayout = styled.nav({
  display: 'flex',
  flexDirection: 'column',
  marginRight: 80,
})
const Menu = styled.button<MenuStylesProps>(({ active }: MenuStylesProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '10px 0',
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: '1em',
  fontWeight: 600,
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
  textAlign: 'right',
  textOverflow: 'ellipsis',
  color: active ? '#713fff' : '#555',
  textWeight: 600,
  cursor: 'pointer',
  overflow: 'hidden',
  transition: 'transform .3s ease, color .2s ease',
  ':hover': {
    transform: 'translate3d(6px, 0, 0)',
    color: '#713fff',
  },
}))

const MenuIcon = styled.img({
  width: 18,
  marginLeft: 10,
})

const MenuText = styled.span({})

const Nav: React.FC<NavProps> = ({ category }: NavProps) => {
  const navigate = useNavigate()
  const handleClick = (newCategory: string) => {
    navigate(`/${newCategory}`)
  }

  return (
    <NavLayout>
      <Menu active={category === 'development'} onClick={() => handleClick('development')}>
        <MenuText>Development</MenuText>
        <MenuIcon src={DevelopmentImage} />
      </Menu>
      <Menu active={category === 'design'} onClick={() => handleClick('design')}>
        <MenuText>Design</MenuText>
        <MenuIcon src={DesignImage} />
      </Menu>
      <Menu
        active={category === 'machine_learning'}
        onClick={() => handleClick('machine_learning')}
      >
        <MenuText>Machine Learning</MenuText>
        <MenuIcon src={MachineLearningImage} />
      </Menu>
      <Menu active={category === 'data_analysis'} onClick={() => handleClick('data_analysis')}>
        <MenuText>Data Analysis</MenuText>
        <MenuIcon src={DataAnalysisImage} />
      </Menu>
      <Menu
        active={category === 'project_management'}
        onClick={() => handleClick('project_management')}
      >
        <MenuText>Project Management</MenuText>
        <MenuIcon src={ProjectManagementImage} />
      </Menu>
      <Menu active={category === 'history'} onClick={() => handleClick('history')}>
        <MenuText>History</MenuText>
        <MenuIcon src={HistoryImage} />
      </Menu>
      <Menu active={category === 'mathematics'} onClick={() => handleClick('mathematics')}>
        <MenuText>Mathematics</MenuText>
        <MenuIcon src={MathmaticsImage} />
      </Menu>
      <Menu active={category === 'science'} onClick={() => handleClick('science')}>
        <MenuText>Science</MenuText>
        <MenuIcon src={ScienceImage} />
      </Menu>
      <Menu active={category === 'economics'} onClick={() => handleClick('economics')}>
        <MenuText>Economics</MenuText>
        <MenuIcon src={EconomicsImage} />
      </Menu>
    </NavLayout>
  )
}

export default Nav
