import styled from '@emotion/styled'
import { RiFileCopyLine } from 'react-icons/ri'
import useClipboard from 'react-use-clipboard'

const ShareLinkLayout = styled.button({
  display: 'flex',
  minWidth: 140,
  height: 40,
  padding: '0 10px',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 20,
  border: 'none',
  borderRadius: 20,
  backgroundColor: '#e2e2e2',
  color: '#555',
  cursor: 'pointer',
  boxSizing: 'border-box',
  ':hover': {
    backgroundColor: '#eaeaea',
  },
  ':active': {
    backgroundColor: '#dadada',
  },
})

const CopyIcon = styled(RiFileCopyLine)({
  fontSize: '1.5em',
})

const ShareLinkText = styled.span({
  display: 'inline-block',
  marginLeft: 6,
  marginRight: 2,
  fontWeight: 600,
})

const ShareLink: React.FC = () => {
  const [isCopied, setCopied] = useClipboard(window.location.href)

  return (
    <ShareLinkLayout onClick={setCopied}>
      <CopyIcon />
      <ShareLinkText>{isCopied ? 'Copied!' : 'Copy link'}</ShareLinkText>
    </ShareLinkLayout>
  )
}

export default ShareLink
