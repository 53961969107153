import styled from '@emotion/styled'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Card from './Card'

interface ContentProps {
  category?: string
  searchKeyword?: string
}

const SearchResultCremb = styled.p({
  display: 'flex',
  height: '1em',
  marginBottom: 20,
  alignItems: 'center',
})

const ContentLayout = styled.div({
  flex: 'auto',
})

const Cards = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, 120px)',
  gap: 40,
  gridColumn: 'span 8 / auto',
  gridAutoRows: 'minmax(min-content, max-content)',
  '@media (max-width: 1600px)': {
    gridTemplateColumns: 'repeat(auto-fill, 104px)',
  },
  '@media (max-width: 1200px)': {
    gridTemplateColumns: 'repeat(auto-fill, 82px)',
  },
  '@media (max-width: 764px)': {
    gridTemplateColumns: 'repeat(auto-fill, 52px)',
  },
})

const SearchPrefix = styled.span({
  display: 'inline-block',
})

const Keyword = styled.span({
  display: 'inline-block',
  maxWidth: '30em',
  color: '#713fff',
  fontWeight: 600,
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineHeight: 1.6,
})

interface Course {
  id: string
  title: string
  thumbnailUrl: string
  tags: string[]
}

interface CourseResponse {
  id: string
  title: string
  thumbnail_url: string
  tags: string[]
}

const Content: React.FC<ContentProps> = ({ category, searchKeyword }: ContentProps) => {
  const dummyCourses: Course[] = Array.from(Array(10).keys()).map<Course>((key) => ({
    id: String(key),
    title: '',
    thumbnailUrl: '',
    tags: [],
  }))
  const [courses, setCourses] = useState<Course[]>(dummyCourses)
  const [loading, setLoading] = useState(true)
  const defaultCategory = 'development'

  useEffect(() => {
    const loadAsyncStuff = async () => {
      setLoading(true)
      setCourses(dummyCourses)

      const params = { search_keyword: searchKeyword }
      localStorage.setItem('category', category || defaultCategory)

      try {
        const response = await axios.get<CourseResponse[]>(
          `https://classhub-backend.dev.azure.riiid.cloud/courses/${
            searchKeyword ? 'all' : category || defaultCategory
          }`,
          {
            params,
          },
        )
        const data = response.data.map(({ id, title, thumbnail_url: thumbnailUrl, tags }) => ({
          id,
          title,
          thumbnailUrl,
          tags,
        }))
        setCourses(data)
      } catch (error) {
        setLoading(false)
        throw error
      } finally {
        setLoading(false)
      }
    }

    loadAsyncStuff()
  }, [category, searchKeyword])

  return (
    <ContentLayout>
      {searchKeyword && (
        <SearchResultCremb>
          <SearchPrefix>Search results of&nbsp;</SearchPrefix>
          <Keyword>&#34;</Keyword>
          <Keyword>{searchKeyword}</Keyword>
          <Keyword>&#34;</Keyword>
        </SearchResultCremb>
      )}
      <Cards>
        {courses.map(({ id, title, thumbnailUrl, tags }) => (
          <Card
            key={id}
            id={id}
            loading={loading ? 1 : 0}
            title={title}
            thumbnailUrl={thumbnailUrl}
            tags={tags}
          ></Card>
        ))}
      </Cards>
    </ContentLayout>
  )
}

export default Content
